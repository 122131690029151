.loadingContainer {
  width: stretch;
  /* width: -webkit-fill-available; */
  height: 100%;
  top: 0;

  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  /* min-height: 597.4px; */
}
.fullLoadingContainer {
  width: stretch;
  /* width: -webkit-fill-available; */
  height: 100vh;
  top: -10px;
  left: 0;
  width: 100%;
  z-index: 800;

  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  /* min-height: 597.4px; */
}
.loadingContainerOverlay {
  width: stretch;
  /* width: -webkit-fill-available; */
  height: 100%;
  left: -10px;
  top: 0;
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;

  background-color: #000;
  opacity: 0.3;
  z-index: 800;
}
.loadingSpinner {
  z-index: 100;
}
.containerLoading {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.required {
  border-left: 3px solid #17a2b8;
  padding-left: 7px;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-radius: 0px;
}
.infoIcon {
  color: #17a2b8;
}
.required.is-invalidText {
  border-left: 3px solid #dc3545 !important;
  padding-left: 7px;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
}
.errorMessage {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-group.is-invalid {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.is-invalidText {
  color: #dc3545;
}

.moduleFormImagePreview {
  height: auto;
  max-height: 100px;
  max-width: 100px;
}

.react-fancybox .thumbnail img {
  max-width: 100%;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.cmsModuleArrayListing .badge {
  margin-right: 5px;
}

.cmsModuleImageView div.thumbnail {
  max-width: 150px;
  max-height: 150px;
}

/* My Custom Styling */

.mydatatable {
}

.mydatatable .header {
}

.mydatatable .header .listing-count-options {
  display: "inline-block";
}
/* .listing-count-options {
  width: 75%;
} */
.footerTextCenter {
  text-align: right;
}
.footerPagination {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.mydatatable .footer {
  margin: 0 !important;
  padding: 0 !important;
}

/* .mydatatable .searchbox{
  float:right !important;
} */

/* .mydatatable .searchbox .input-group .input{
  width:200px;
} */

.mydatatable td.record-actions {
  width: 130px;
}

.mydatatable td.record-actions.MoreItem1 {
  width: 155px;
}

.mydatatable td.record-actions.MoreItem2 {
  width: 190px;
}

.mydatatable td.record-actions.MoreItem3 {
  width: 225px;
}

.mydatatable td.record-actions a {
  margin: 1px;
}

.mydatatable .serch-close {
  position: absolute;
  right: 40px;
  top: 3px;
  cursor: pointer;
}

.inDashboardErrorPage {
  margin: 0 auto;
  padding-top: 190px;
}

.resetFilters {
  color: orange;
  position: absolute;
  right: 0px;
  top: -25px;
  z-index: 100;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
}

.mydatatable td.checkboxCell {
  width: 40px;
  text-align: center;
}

/* members */
.memberName {
  text-decoration: none;
  color: #000;
}

.details {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.boardMemberText {
  background-color: #a2231d;
  color: #fff;
  padding: 0 10px;
  border-radius: 0px;
  font-size: 14px;
  font-weight: "400";
  margin-left: 10px;
}
.greenBackgroundText {
  background-color: #169d57;
  color: #fff;
  padding: 0 10px;
  border-radius: 0px;
  font-size: 14px;
  font-weight: "400";
  margin-left: 10px;
}
.normalText {
  color: #a2231d;
  border-radius: 0px;
  font-size: 13px;
  font-weight: "400";
  margin-left: 10px;
}
.hasCMSAccessText {
  background-color: #169d57;
  color: #fff;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: "500";
  margin-left: 10px;
}

.hasMemberDetailsAccessText {
  background-color: #04799d;
  color: #fff;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: "500";
  margin-left: 10px;
}
.details .headerText {
  background-color: #d6d6d6;
  width: 170px;
  border-radius: 0;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
}
.font-small {
  font-size: 14px;
}
.label {
  font-size: 13px;
}
.profile-subimage {
  left: 0px;
  top: -02px;
}
.icon-small {
  color: rgb(255, 255, 255);
  font-size: 10px;
  margin-left: 2px;
}
.success {
  background-color: green;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  border-radius: 50%;
}
.reject {
  background-color: orangered;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  border-radius: 50%;
}

.carouselimage {
  width: 150px;
  height: 150px;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 0rem !important;
  margin-left: 15%;
  list-style: none;
}
.carousel {
  height: 350px;
}
.name {
  color: rgb(29, 64, 116);
}
.registrationCard {
  width: 175px;
  height: 100%;
}

@media (max-width: 600px) {
  .card-header > .card-tools {
    float: left !important;
    margin-right: -0.625rem;
    margin-top: 10px;
  }
  .noLoading .loadingContainer {
    display: none;
  }
  .noLoading .fullLoadingContainer {
    display: none;
  }
  .noLoading .loadingContainerOverlay {
    display: none;
  }
  .cardHead > .card-tools {
    float: left !important;
    margin-right: -0.625rem;
    margin-top: 0px !important;
  }
  .listingPage .listing .item .card-tools {
    margin-top: 5px;
    width: 100%;
  }
  .carousel-image {
    height: auto;

    object-fit: cover;
    width: 100%;
  }
  .fade.modal.show {
    width: 100vw;
  }
  .border-themePrimary {
    border: 1px solid #f1b6b3;
    /* background-color: #f1b6b3; */
  }
  .react-fancybox .box .image-box {
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    position: relative;
    width: 92%;
  }
  .react-fancybox .box .image-box img {
    width: 100%;
  }
  .react-fancybox .close-btn {
    background-color: white;
    width: 25px !important;
    border-radius: 50%;
    height: 25px;
    position: absolute;
    right: -13px;
    top: -13px;
  }
  .resetFilters {
    color: orange;
    position: absolute;
    right: 0px;
    top: -30px;
    z-index: 100;
    cursor: pointer;
    text-decoration: underline;
    font-style: italic;
  }
  .listingPage {
    margin-top: 20px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer .col {
    margin-bottom: 10px;
  }
  .title100 {
    width: 100%;
  }
  .footer .text-right,
  .footer .text-center {
    display: flex;

    align-items: center;
    justify-content: flex-start;
  }
  .details .headerText {
    background-color: #d6d6d6;
    width: 100%;
    border-radius: 0;
    margin: 0;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .memberDashboardRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start !important;
    -moz-align-items: flex-start !important;
    -ms-align-items: flex-start !important;
    align-items: flex-start !important;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .listing-count-options {
    width: 100%;
  }
  .footerTextCenter {
    text-align: left;
  }
  .footerPagination {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }
  .hideOnMobile {
    display: none;
  }
  .mobileOverflow {
    overflow: auto;
  }
  .lastMessage {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
  }
  .moduleHeaderTopButtons {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .cardHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cardHead .card-title {
    flex: 1;
  }
  .cardHead .card-tools {
    flex: 1;
  }
  .cardHead .card-tools a {
    float: inline-end;
  }
}

.moduleHeaderTopButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
@media (min-width: 600px) {
  .carousel-image {
    height: auto !important;
    max-height: auto !important;
    object-fit: contain;
    width: 100%;
  }
  .carousel {
    height: auto !important;
  }
  .showOnMobile {
    display: none;
  }
  .item .card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .card-title {
    flex: 1;
  }
}

.statusIcon {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  border-radius: 50%;
}

.memberStatusImage {
  width: 20px;
  margin-left: 10px;
}

.membershipId {
  margin-top: 25px;
}

.listingPage .paginationSummary {
  line-height: 32px;
  margin-right: 5px;
}

.listingPage .pagination {
  margin-bottom: 0;
}

/* .listingPage .listing .item .card-tools {
  margin-top: -5px;
} */

.listingPage .listing .item .card-tools .listingActionIcon {
  margin: 0 2px;
  padding: 1px 6px;
}

.emailLink {
  cursor: pointer;
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.emailLink:hover {
  text-decoration: underline;
}

.ImageSectionSpacing {
  padding-top: 7px;
  padding-bottom: 5px;
}

.listingPage .listing .selectAll {
  margin-left: 4px;
}
/* .banned{
  position: absolute;
  font-size: 41px;
  color: red;
  left:-5px;
  top: -1px;
  opacity: 0.4;
} */
.banned {
  position: absolute;
  font-size: 45px;
  color: red;
  left: -7px;
  top: 0px;
  opacity: 0.4;
}
.banned2 {
  position: absolute;
  font-size: 52px;
  color: red;
  left: 0px;
  top: 0px;
  opacity: 0.4;
}
.usericon {
  font-size: 36px;
  color: gray;
}

.cursor-move {
  cursor: move;
}
.dragableContent {
  cursor: move;
  border: 1px dashed gray;
  background-color: #eee;
}
.listStyle {
  list-style-type: none;

  cursor: move;
}

.carImage {
  max-height: 250px;
}
.carImage img {
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 250px;
}
.accordion-button {
  padding: 8px 8px;
}
.pageContent .react-fancybox .thumbnail {
  width: 150px;
  min-height: 150px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageContent .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
}
.pageContent .react-fancybox {
  width: 150px;
  min-height: 150px;
  margin-bottom: 20px;
}
.pageContent .iconDiv {
  background-color: #ccc;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 130px;
  top: -5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  z-index: 9999;
}

.formImagePreview {
  max-width: 200px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
}
.imagePreview {
  display: flex;
  flex-direction: row;
}

.croppingArea {
  position: relative;
  width: 300px;
  height: 200px;
  background: rgb(167, 163, 163);
  margin-bottom: 10px;
}
.imageThumbArea {
  display: flex;
  flex-direction: row;
}

.imageActionsContainer {
  width: 100%;
  /* height:50px; */
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 180px;
}
.imageActionsContainerBack {
  width: 100%;
  /* height:50px; */
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 280px;
}

.imageAction {
  width: 30px;
  height: 30px !important;
  margin: 0px;
  padding: 0px;
  margin-top: -4px;
  margin-bottom: 35px;
  background: rgb(221, 220, 220);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageAction i {
  font-size: 20px;
}

.moduleHeader h4 {
  padding-top: 5px;
  margin: 0px;
}

/* gallery */

.roundButton {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
.galleryVideo {
  width: 100%;
  height: 100%;
  max-width: 400px;
  position: relative;
}
.galleryVideo .holdsTheIframe {
  width: 100%;
  height: auto;
  min-height: 200px;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
}
.videoIcon {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  font-size: 2.2em;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
}
.galleryImageVideo {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background-color:#eee;
}
.videoModal .modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.videoModal .modal-body {
  position: relative;
  padding: 0px;
}
.closeVideo {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 999;
  font-size: 2rem;
  border-radius: 50%;
  font-weight: normal;
  color: #000;
  background-color: #fff;
  opacity: 1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoThumbnail {
  max-width: 320px;
}
.galleryImage {
  max-width: 200px;
}
.galleryImage2 {
  width: 100%;
  text-align: center;
  min-height: 100px;
  background-color: #eee;
}
.galleryImage2 .react-fancybox .thumbnail img {
  width: 100%;
  height: 200px;
  object-fit: contain;

  text-align: center;
}
.galleryImage2 .react-fancybox {
  width: 100%;
}
.galleryImage .react-fancybox .thumbnail img {
  width: 100%;
  height: auto;
  max-width: 200px;
}
.galleryImage .react-fancybox {
  max-width: 200px;
  margin-bottom: 20px;
}
.galleryImage.noMargin .react-fancybox {
  max-width: 200px;
  margin-bottom: 0px;
}
.custom-file-label.is-invalid {
  border: 1px solid #dc3545 !important;
}
.custom-file-label.is-invalid::after {
  color: #dc3545 !important;
}
#is-invalidInputGroupFileAddon01 {
  border: 1px solid #dc3545 !important;
  color: #dc3545;
}

.pictureTitle {
  font-size: 20px;
  font-weight: bold;
}
.uploadedBy {
  font-size: 16px;
  font-weight: normal;
  /* font-style: italic; */
  color: #444;
  margin-top: 3px;
}
.photoInsideAlbum {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* text-editor */

.rdw-editor-toolbar {
  border: 1px solid #ccc !important;
}

/* comment */
.commentContainer {
  display: flex;
}
.recent-comments .direct-chat-info .right > .direct-chat-text::after,
.direct-chat-info .right > .direct-chat-text::before {
  border-left-color: transparent;
}
.recent-comments .direct-chat-info .right > .direct-chat-text {
  background-color: #e3e7ed;
  border-color: #e3e7ed;
}
/* likes */
.recent-likes .direct-chat-info .right > .direct-chat-text::after,
.direct-chat-info .right > .direct-chat-text::before {
  border-left-color: transparent;
}
.recent-likes .direct-chat-info .right > .direct-chat-text {
  background-color: #e3e7ed;
  border-color: #e3e7ed;
}
.chatTime {
  font-style: italic;
  font-size: 12px;
}
.memberMessage {
  background-color: #dbd1ff;
  border-color: #dbd1ff;
}
.memberMessage.direct-chat-text::before {
  border-right-color: #dbd1ff !important;
}
.memberMessage.direct-chat-text::after {
  border-right-color: #dbd1ff !important;
}
/* circle */
.memberRemove {
  height: 20px;
}
.emailDisplay .emailLink {
  color: #000 !important;
}

.searchWrapper {
  border: 0px !important;
  margin-top: -10px;
}
.likesCommentsLinks {
  width: 100%;
}
label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 400 !important;
}
.rdw-link-modal-input {
  border: 1px solid rgb(113, 112, 112);
}

.rdw-link-modal {
  min-height: 255px;
}

.DraftEditor-root {
  z-index: 0;
}
.customDatePicker {
  width: 100%;
}
.optionListContainer.displayBlock {
  left: 0;
}
.input-group {
  z-index: 0;
}
.pastActivity {
  background-color: #fbec5d;
}
.rdw-editor-wrapper {
  margin-bottom: 15px;
}
.accordion-item {
  margin-bottom: 10px;
}
/* .accordionBorder {
  border-top: 2px solid #169d57;
}
.accordionBorderMember {
  border-top: 2px solid #169d57;
} */

.messageItem {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-top: 2px solid rgb(163, 163, 163);
}

.paymentPending {
  background-color: #ffa011;
}
.resubmission {
  letter-spacing: 1px;
}
.form-control.select {
  appearance: auto !important;
}
.hoveredText {
  color: #000;
}
.hoveredText:hover {
  color: navy;
}
.text-normal {
  font-weight: normal;
}
.multiSelectContainer {
  height: auto !important;
}
.warningDark {
  background-color: rgba(255, 150, 17, 0.6);
}
.dashboardConflictContainer {
  background-color: #ddd;
  border-radius: 5px;
}
.dashboardCarDetails {
  display: flex !important;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
}
.dashboardCarDetails div {
  /* padding: 0px 10px; */
  flex: 1;
}
@media (max-width: 500px) {
  .separation {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin-top: 10px;
  }
}
@media (min-width: 1360px) {
  .dashboardCarDetails {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
  }
  .separation {
    border-left: 1px solid #ccc;
  }
}
.displayEmails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  max-height: 100px;
  overflow-y: scroll;
}
.displayEmails .emailItem {
  width: fit-content;
  height: 25px;
  background-color: #ddd;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: #000;
}
.displayEmails .emailItem .emailRemoveIcon {
  color: rgb(0, 0, 0);
  font-size: 12px;
  cursor: pointer;
  width: 15px;
  height: 15px;
}
@media (max-width: 767.98px) {
  .error-page > .error-content > p {
    text-align: center;
  }
}

.dropButton {
  background-color: #007bff;
  border-radius: 3px;
  height: 37px;
}
.dropButton a {
  color: #fff;
}
.dropButton a:hover {
  color: #fff;
}
.dropButton a:active {
  color: #fff;
}
.dropButton a:focus {
  color: #fff;
}
.removeCursor {
  cursor: default;
}
.carTools {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dropdown-item {
  cursor: pointer;
}
.activeStatusInline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(34, 192, 68);
}
.notActiveStatusInline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #a2231d;
}
.accountDeleteIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: rgb(0, 0, 0); */
  position: absolute;
  left: -5px;
  top: 0px;
  z-index: 150;
}
.accountDeleteIcon i {
  color: #000;
  font-size: 18px;
}
.chairmanStatus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: rgb(0, 0, 0); */
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 150;
}
.chairmanStatus i {
  color: #ffd700;
  font-size: 24px;
}
.chairmanStatus i:hover {
  color: #169d57;
  -webkit-animation: heartbeat 1.5s ease-in-out 0s 3 both;
  animation: heartbeat 1.5s ease-in-out 0s 3 both;
}
.activeStatus {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(34, 192, 68);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
}
.notActiveStatus {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #a2231d;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
}
.activeStatusBig {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(34, 192, 68);
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 100;
}
.notActiveStatusBig {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #a2231d;
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 100;
}
.background {
  background-color: #eee;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
}
.readMessage {
  color: rgb(51, 171, 49);
  font-size: 18px;
}
.unreadMessage {
  color: gray;
  font-size: 18px;
}
.invisibleButtonBorder {
  background-color: transparent;
  border-color: white;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.invisibleButton {
  background-color: transparent;
  border-color: transparent;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.invisibleButton:hover {
  background-color: transparent;
  border-color: transparent;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.invisibleButton:focus {
  background-color: transparent;
  border-color: transparent;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.invisibleButton:active {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.memberDashboardRow {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}

/* theme */
.bg-themePrimary {
  background-color: #fff;
  /* color: #fff; */
}

.text-themePrimary {
  color: #a2231d;
}
.primary {
  background-color: #a2231d !important;
  border: 1px solid #a2231d !important;
  color: #fff !important;
}
.secondary {
  background-color: #169d57 !important;
  border: 1px solid #169d57 !important;
  /* color: #a2231d !important; */
}
.darkTheme {
  background-color: #a2231d;
  border: 1px solid #a2231d;
  color: #ecc57e;
}

.primary:hover {
  background-color: #a2231d !important;
  border: 1px solid #a2231d !important;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav-tabs .nav-link {
  color: #a2231d !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #a2231d !important;
  border: 1px solid #a2231d !important;
}
/* .dashboardAccordion .hoveredText{
  color: #fff !important;
}
.dashboardAccordion .text-success{
  color: #000 !important;
} */
.dashboardAccordion .accordion-button:not(.collapsed) {
  color: #169d57;
  background-color: #ebeaea !important;
  background-image: none !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed) {
  color: #fff !important;
}
.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #169d57;

  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button {
  font-size: 1rem;
  color: #000 !important;
  border-top: 2px solid #169d57 !important;
  text-align: left;
}

.accordion-button:focus {
  z-index: 3;
  color: #fff;
  outline: 0;
  /* background-color: #FFF;
  background-image: linear-gradient(-45deg,#000 0% 10%, #A2231D 30% 40%, #FFF 50% 70%)!important; */
  box-shadow: 0 0 0 0.05rem rgba(22, 157, 87, 0.25);
}
.card-primary:not(.card-outline) > .card-header {
  background: #9e9a9a;
  color: #fff;
}
.avatar {
  max-height: 45px;
}
.moduleHeader h4 {
  color: #000 !important;
  font-weight: bold;
  text-transform: capitalize;
}
.form-select:focus {
  border: 1px solid rgba(22, 157, 87, 0.1) !important;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(22, 157, 87, 0.1);
}
.searchbox input {
  border: 1px solid rgba(22, 157, 87, 0.1) !important;
  background-color: rgba(22, 157, 87, 0.1);
}
.form-select,
.moduleSearch {
  border: 1px solid rgba(22, 157, 87, 0.1) !important;
  background-color: rgba(22, 157, 87, 0.1);
  color: #169d57;
}
.moduleSearch::placeholder {
  color: #a2231d;
}
.form-select:disabled {
  background-color: #e9ecef;
  border: 1px solid #e9ecef !important;
  color: #444;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  background-color: rgba(22, 157, 87, 0.1);

  --bs-table-accent-bg: none;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
  border-color: rgba(22, 157, 87, 0.1);
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #169d57;
  border-color: #169d57;
}
.page-link:hover {
  z-index: 2;
  color: #a2231d;
  text-decoration: none;
  background-color: rgba(22, 157, 87, 0.1);
  border-color: rgba(22, 222, 87, 0.1);
}
.page-link {
  z-index: 2;
  color: #a2231d;
  text-decoration: none;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #169d57;
}
.nav-pills .nav-link:not(.active):hover {
  color: #a2231d;
}
/* .btn-primary {
	color: #fff;
	background-color: #a2231d;
	border-color: #a2231d;
	box-shadow: none;
}
.btn-primary:hover {
	color: #fff;
	background-color: #a2231d;
	border-color: #a2231d;
	box-shadow: none;
}
.btn-primary:focus {
	color: #fff;
	background-color: #a2231d;
	border-color: #a2231d;
	box-shadow: none;
}
.btn-primary:active {
	color: #fff;
	background-color: #a2231d;
	border-color: #a2231d;
	box-shadow: none;
}
.dropButton {
	background-color: #a2231d;
	border-radius: 3px;
	height: 37px;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #a2231d;
	border-color: #a2231d;
} */
.searchbox .input-group > .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #169d57;
  opacity: 1; /* Firefox */
}
.dashboard-box {
  min-height: 150px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.dashboard-box.action {
  min-height: 50px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 30px;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.dashboard-box.second {
  min-height: 150px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  border: 1px solid #ccc;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.dashboard-box.second .left {
  flex: 2;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.dashboard-box.second .left .dashboard-box-heading {
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
}
.dashboard-box.second .left .dashboard-box-subHead {
  color: #fff;
  font-size: 18px;
  text-align: left;
  font-weight: normal;
  margin-bottom: 10px;
}
.dashboard-box.second .dashboard-box-icon {
  color: #fff;
  font-size: 30px;
  margin: 10px 0px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-box .dashboard-box-icon {
  color: #fff;
  font-size: 30px;
  margin: 5px 0px;
}
.dashboard-box .dashboard-box-heading {
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 8px;
}
.dashboard-box .dashboard-box-text {
  color: #fff;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.2px;
}
.dashboard-box .action {
  position: absolute;
  right: 5px;
  top: 0;
  margin-bottom: 0;
}
.bg-linear-one {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(58, 185, 173) 0%, rgb(20, 158, 158));
}
.bg-linear-black {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(77, 77, 77) 0%, rgb(0, 0, 0));
}
.bg-linear-two {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(231, 145, 54) 0%, rgb(221, 123, 32));
}
.bg-linear-three {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(95, 137, 201) 0%, rgb(29, 87, 162));
}
.bg-linear-four {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(189, 95, 201) 0%, rgb(135, 29, 162));
}
.bg-linear-five {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(24, 172, 185) 0%, rgb(8, 85, 136));
}
.bg-linear-six {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(13, 122, 33) 0%, rgb(62, 165, 10));
}
.bg-linear-yellow {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(247, 204, 62) 0%, rgb(224, 112, 8));
}
.bg-linear-red {
  background: rgb(0, 0, 0);
  background: linear-gradient(45deg, rgb(231, 42, 60) 0%, rgb(165, 10, 10));
}
.bg-linear-primary {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(162, 35, 29, 1) 86%
  );
}
.bg-linear-secondary {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(22, 157, 87, 1) 86%
  );
}
.bg-linear-tertiary {
  background: rgb(22, 157, 87);
  background: linear-gradient(
    -45deg,
    rgba(22, 157, 87, 1) 18%,
    rgba(162, 35, 29, 1) 70%
  );
}
[class*="sidebar-dark-"] {
  background-color: #000;
}
.fs-9 {
  font-size: 16px;
}
.redBorder {
  border: 2px solid #79d8a5;
}
.lastMessage {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.login-logo a,
.register-logo a {
  background: -webkit-linear-gradient(#a2231d, #000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.borderTopRed {
  border: 2px solid #ccc;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-22 15:20:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.accountDeletedBg {
  background-color: #ddd;
}
.accountDeleted {
  border: 1px solid #ccc;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0.08;
  border-radius: 4px;
}

.memberItem:hover .accountDeleted {
  border: 0px solid #ccc;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 4px;
}
.switchLabel {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  min-height: 30px;
}
.video-react .video-react-big-play-button {
  font-size: 5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  background-color: transparent;
  border-radius: 0;
  border: 0px !important;
  transition: all 0.4s;
}
.video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus {
  background-color: transparent;
  transition: all 0s;
}
.memberItemHolder {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.memberItemHolder .memberItemLeft {
  flex: 1;
  max-width: 95px;
  padding: 10px;
}
.memberItemHolder .memberItemRight {
  flex: 3;
  min-height: 100px;
  padding-left: 20px;
  margin-right: 10px;
  margin-top: 10px;
}
.memberItemHolder .memberItemRight .emailLink {
  word-wrap: anywhere;
}
.circleMemberRemoveIcon {
  position: absolute;
  right: 5px;
  top: 1px;
}
.galleryType {
  background-color: rgb(137, 219, 151);
  padding: absolute;
  top: 0;
  padding: 3px 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.galleryType.video {
  background-color: rgb(112, 228, 218);


}

.registrationTable td{

    min-height: 20px;
    vertical-align: middle;

}
.registrationTable tbody tr{
  background-color: rgba(22, 157, 87, 0.1);
  --bs-table-accent-bg: none;
}
.bg-table{
  background-color: rgba(59, 160, 81, 0.3)!important;
  --bs-table-accent-bg: none;
}
.borderBottom{
  border-bottom-color: #7b867f!important;
  border-bottom-width: 1px!important;
}